.ppcity {
  .card-group .card {
    max-width: 30em;
    margin: 1em;
  }

  .card-group .card .card-img {
    padding: 1.25rem;
  }

  .card-group a {
    color: #23282c;
    font-weight: normal !important;
    text-decoration: none !important;
  }

  .card-group a:hover {
    color: #23282c;
    font-weight: normal !important;
    text-decoration: none !important;
  }

  .card-group .card:hover {
    border-color: #E20061;
    border-width: 1px;
    border-style: solid;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #23282c;
    background-color: rgba(255, 0, 0, 0);
    border-color: rgba(255, 0, 0, 0);
  }

  .btn-secondary:hover {
    border-color: #E20061;
    border-width: 1px;
    border-style: solid;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .btn:hover {
    color: #23282c;
    background-color: rgba(255, 0, 0, 0);
    text-decoration: none;
  }

  button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
  }

  .btn-secondary {
    color: #23282c;
    background-color: rgba(255, 0, 0, 0);
    border-color: rgba(255, 0, 0, 0);
  }

  .already-subscribed-banner {
    position: absolute;
    top: 1.5rem;
    color: #e20061;
    background-color: rgba(0,0,0,0.5);

    a {
      color: #e20061;
      text-transform: uppercase;
    }
  }
}